(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@godaddy/request")["default"], require("@ux/button")["default"], require("@ux/component")["default"], require("@ux/modal")["default"], require("@ux/namespace-component")["default"], require("@ux/spinner")["default"], require("@ux/tooltip")["default"], require("@ux/util")["default"], require("prop-types"), require("react"), require("react-dom"), require("react-intl")["default"], require("react-trfq")["default"]);
	else if(typeof define === 'function' && define.amd)
		define("SalesHeader", ["@godaddy/request", "@ux/button", "@ux/component", "@ux/modal", "@ux/namespace-component", "@ux/spinner", "@ux/tooltip", "@ux/util", "prop-types", "react", "react-dom", "react-intl", "react-trfq"], factory);
	else if(typeof exports === 'object')
		exports["SalesHeader"] = factory(require("@godaddy/request")["default"], require("@ux/button")["default"], require("@ux/component")["default"], require("@ux/modal")["default"], require("@ux/namespace-component")["default"], require("@ux/spinner")["default"], require("@ux/tooltip")["default"], require("@ux/util")["default"], require("prop-types"), require("react"), require("react-dom"), require("react-intl")["default"], require("react-trfq")["default"]);
	else
		root["SalesHeader"] = factory(root["ux"]["request"], root["ux"]["Button"], root["ux"]["Component"], root["ux"]["Modal"], root["ux"]["NamespaceComponent"], root["ux"]["Spinner"], root["ux"]["Tooltip"], root["ux"]["utils"], root["PropTypes"], root["React"], root["ReactDOM"], root["ux"]["intl"], root["ux"]["trfq"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__godaddy_request__, __WEBPACK_EXTERNAL_MODULE__ux_button__, __WEBPACK_EXTERNAL_MODULE__ux_component__, __WEBPACK_EXTERNAL_MODULE__ux_modal__, __WEBPACK_EXTERNAL_MODULE__ux_namespace_component__, __WEBPACK_EXTERNAL_MODULE__ux_spinner__, __WEBPACK_EXTERNAL_MODULE__ux_tooltip__, __WEBPACK_EXTERNAL_MODULE__ux_util__, __WEBPACK_EXTERNAL_MODULE_prop_types__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_dom__, __WEBPACK_EXTERNAL_MODULE_react_intl__, __WEBPACK_EXTERNAL_MODULE_react_trfq__) {
return 